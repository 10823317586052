/** ***************************************************************
* Copyright 2020 Advanced Distributed Learning (ADL)
*
* Licensed under the Apache License, Version 2.0 (the "License");
* you may not use this file except in compliance with the License.
* You may obtain a copy of the License at
*
*     http://www.apache.org/licenses/LICENSE-2.0
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS,
* WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
* See the License for the specific language governing permissions and
* limitations under the License.
**************************************************************** */
import React from 'react';

import { connect } from 'react-redux';


class LoadingSpinner extends React.Component {
    render() {
        if (this.props.loading)
            return <>
                <div className="profile-lightbox">
                    <div className="profile-lightbox-inner">
                        <span style={{ fontSize: '34px' }} className="fa fa-gear fa-spin"></span>
                    </div>
                </div>
            </>;
        return "";
    }
}


const mapStateToProps = (state) => ({
    loading: state.application.loading > 0
});

const mapDispatchToProps = () => ({

});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LoadingSpinner);


