/** ***************************************************************
* Copyright 2020 Advanced Distributed Learning (ADL)
*
* Licensed under the Apache License, Version 2.0 (the "License");
* you may not use this file except in compliance with the License.
* You may obtain a copy of the License at
*
*     http://www.apache.org/licenses/LICENSE-2.0
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS,
* WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
* See the License for the specific language governing permissions and
* limitations under the License.
**************************************************************** */
import React from 'react';
import { Formik, Field } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import ErrorValidation from '../controls/errorValidation';
import Iri, { isValidIRI } from '../fields/Iri';
import Tags from '../fields/Tags';
import Translations from '../fields/Translations';
import { useSelector } from 'react-redux';
import { Detail } from '../DetailComponents';
import CancelButton from '../controls/cancelButton';
import DeleteButton from '../controls/DeleteButton';
import ValidationControlledSubmitButton from '../controls/validationControlledSubmitButton';

export default function CreateProfileForm({ initialValue, handleSubmit, handleCancel, handleDeleteProfile, handleDeleteProfileDraft }) {

    const profileRootIRI = useSelector(state => state.application.profileRootIRI);

    const isPublished = initialValue && initialValue.state === "published";

    let generatedIRIBase = `${profileRootIRI}/${uuidv4()}`;

    const formValidation = (values) => {
        const errors = {};
        if (!values.name) errors.name = 'Required';
        if (!values.description) errors.description = 'Required';

        if (values.iriType === 'external-iri') {
            if (!values.extiri) errors.extiri = 'Required';
            else if (!isValidIRI(values.extiri)) errors.extiri = 'IRI did not match expected format.';
        }
        if (values.moreInformation) {
            if (!isValidIRI(values.moreInformation)) 
                errors.moreInformation = 'More Information needs to be a URL.';
        }
        return errors;
    }

    return (<>
        <Formik
            initialValues={initialValue || {
                iriType: "external-iri", iri: '', extiri: '', geniri: '', name: '', description: '', translations: [], moreInformation: '', tags: [],
            }}
            validate={formValidation}
            validateOnMount={true}
            onSubmit={(values) => {
                if (!initialValue && values.iriType === 'generated-iri') {
                    values.iri = generatedIRIBase;
                } else if (!initialValue && values.iriType === 'external-iri') {
                    // remove trailing slashes from IRIs
                    values.iri = values.extiri.replace(/\/$/, '');
                }
                handleSubmit(values);
            }}
        >
            {(formikProps) => (<>
                <div className="grid-container border-1px border-base-lighter padding-bottom-4 padding-left-4">
                    <div className="grid-row">
                        <h2 className="grid-col-5">Profile Details</h2>
                        <div className="grid-col">
                            <div className="margin-top-3">
                                <span className="text-secondary">*</span> <span className="text-thin text-base font-sans-3xs">indicates required field</span>
                            </div>
                        </div>
                    </div>
                    <form className="usa-form">
                        {isPublished || (initialValue) ?
                            <Detail title='IRI' subtitle="The IRI used to identify this in an xAPI statement.">
                                <span className="padding-top-05">{formikProps.values.iri}</span>
                            </Detail>
                            :
                            <Iri message="This profile already has an IRI that is used in xAPI statements"
                                sublabel={<>The IRI is what you will use to identify this concept in xAPI statements. A portion of the IRI depends on the IRI for the profile.
                                You may use an IRI that is managed externally or one that is generated by the profile server.
                                <span className="text-bold"> This is permanent and cannot be changed.</span></>}
                                {...formikProps} generatedIRIBase={generatedIRIBase} profileIRI={true} isPublished={isPublished} />
                        }
                        <ErrorValidation name="name" type="input">
                            <label className="usa-label" htmlFor="name"><span className="text-secondary">*</span><span className="details-label"> Name</span></label>
                            <Field name="name" type="text" className="usa-input" id="input-name" aria-required="true" />
                        </ErrorValidation>

                        <ErrorValidation name="description" type="input">
                            <label className="usa-label" htmlFor="description"><span className="text-secondary">*</span><span className="details-label"> Description</span></label>
                            <Field name="description" component="textarea" rows="3" className="usa-textarea" id="input-description" aria-required="true" />
                        </ErrorValidation>

                        <label className="usa-label" htmlFor="translations"><span className="details-label">Translations</span></label>
                        <Field name="translations" component={Translations} id="translations" />

                        <ErrorValidation name="moreInformation" type="input">
                            <label className="usa-label" htmlFor="moreInformation"><span className="details-label">More Information</span></label>
                            <Field name="moreInformation" type="text" className="usa-input" id="input-moreInformation" />
                        </ErrorValidation>

                        <label className="usa-label" htmlFor="tags">
                            <span className="details-label">Tags</span> <br />
                            <span className="usa-hint font-ui-3xs">Put a comma between each one. Example: <strong>tag 1, tag 2, tag 3</strong></span>
                        </label>
                        <Field name="tags" component={Tags} className="usa-input" id="input-tags" />
                    </form>
                </div>

                <div className="grid-row">
                    <ValidationControlledSubmitButton errors={formikProps.errors} className="usa-button submit-button" type="button" onClick={formikProps.handleSubmit}>
                        {
                            initialValue ? "Save Changes" : "Create Profile"
                        }
                    </ValidationControlledSubmitButton>
                    
                    <CancelButton 
                        className="usa-button usa-button--unstyled"
                        style={{ marginTop: "0.8em" }}
                        type="reset" 
                        cancelAction={handleCancel} 
                    />

                    {(isPublished) ?
                        <div className="grid-col display-flex flex-column flex-align-end">
                            <DeleteButton
                                className="usa-button usa-button--unstyled text-secondary-dark text-bold"
                                style={{ marginTop: "1.6em" }}
                                type="reset"
                                onConfirm={handleDeleteProfile}
                                componentType="profile"
                            />
                        </div>
                        :
                        <div className="grid-col display-flex flex-column flex-align-end">
                            <DeleteButton
                                className="usa-button usa-button--unstyled text-secondary-dark text-bold"
                                style={{ marginTop: "1.6em" }}
                                type="reset"
                                onConfirm={handleDeleteProfileDraft}
                                componentType="profile draft"
                            />
                        </div>
                    }
                </div>
                
            </>)}
        </Formik>
    </>
    )
}
