/** ***************************************************************
* Copyright 2020 Advanced Distributed Learning (ADL)
*
* Licensed under the Apache License, Version 2.0 (the "License");
* you may not use this file except in compliance with the License.
* You may obtain a copy of the License at
*
*     http://www.apache.org/licenses/LICENSE-2.0
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS,
* WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
* See the License for the specific language governing permissions and
* limitations under the License.
**************************************************************** */
import React from 'react'
import { Field } from 'formik';

import Iri from '../fields/Iri';
import ErrorValidation from '../controls/errorValidation';
import Translations from '../fields/Translations';
import { Detail } from '../DetailComponents';

export default function BaseConceptFields(props) {
    return (
        <div>
            {props.isPublished || (props.isEditing) ?
                <Detail title='IRI' subtitle="The IRI used to identify this in an xAPI statement.">
                    {props.values.iri}
                </Detail>
                :
                <Iri
                    message="This concept already has an IRI that is used in xAPI statements"
                    sublabel={props.isPublished ? '' : <>The IRI is what you will use to identify this concept in xAPI statements. A portion of the IRI depends on the IRI for the profile.
                    You may use an IRI that is managed externally or one that is generated by the profile server.
                        <span className="text-bold"> This is permanent and cannot be changed.</span></>}
                    {...props}
                />
            }
            {
                props.isPublished ?
                    <Detail title='concept name' subtitle='English (en)'>
                        {props.values.name}
                    </Detail>
                    :
                    <ErrorValidation name="name" type="input">
                        <label className="usa-label" htmlFor="name">
                            <span className="text-secondary">*</span> <span className="details-label">concept name</span><span className="details-subtitle">&nbsp;&nbsp;-&nbsp;&nbsp;English (en)</span>
                        </label>
                        <Field name="name" type="text" className="usa-input" id="name" aria-required="true" disabled={props.isPublished} />
                    </ErrorValidation>
            }
            {
                props.isPublished ?
                    <Detail title='description' subtitle='English (en)'>
                        {props.values.description}
                    </Detail>
                    :
                    <ErrorValidation name="description" type="input">
                        <label className="usa-label" htmlFor="description">
                            <span className="text-secondary">*</span> <span className="details-label">description</span><span className="details-subtitle">&nbsp;&nbsp;-&nbsp;&nbsp;English (en)</span>
                        </label>
                        <Field name="description" component="textarea" rows="2" className="usa-textarea" id="description" aria-required="true" disabled={props.isPublished} />
                    </ErrorValidation>
            }

            <label className="usa-label" htmlFor="translations"><span className="details-label">Translations</span></label>
            <Field name="translations" component={Translations} id="translations"></Field>
        </div>

    )
}
